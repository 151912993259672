import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Liquidity',
        href: 'https://dex.mainfarm.io/#/pool',
      },
      {
        label: 'Fiat',
        href: 'https://dex.mainfarm.io/#/fiat',
      },
      {
        label: 'Cross Swap',
        href: 'https://swap.mainfarm.io/',
      },
      {
        label: 'Futures',
        href: 'https://futures.mainfarm.io/',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Referral',
  //   icon: 'GroupsIcon',
  //   href: '/referral',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'Collectibles',
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: '/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: '/ifo',
  // },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
 //     {
 //       label: 'Voting',
 //       href: 'https://voting.mainfarm.io',
 //     },
      {
        label: 'Info',
        href: 'https://www.dextools.io/app/en/bnb/pair-explorer/0x7924cceb679f8c7a1a9ab0426dfaa4c4c766f852',
      },
      {
        label: 'Github',
        href: 'https://github.com/Mainfarm',
      },
      {
        label: 'Docs',
        href: 'https://docs.mainfarm.io',
      },
      {
        label: 'Audit',
        href: 'https://docs.mainfarm.io/security/audit',
      },
      {
        label: 'Community',
        href: 'https://docs.mainfarm.io/community',
      },
    ],
  },
]

export default config
